import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const pageQuery = graphql`
  query ProjectByUid($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        paragraph {
          text
          html
        }
        related_project {
          uid
          slug
        }
        pdf_project {
          url
        }
        body {
          ... on PrismicProjectBodyImageElement {
            slice_type
            primary {
              image1 {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectBodyImagePortrait {
            slice_type
            primary {
              image1 {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectBodyVideoElement {
            slice_type
            primary {
              video_url {
                url
              }
            }
          }
        }
      }
    }
  }
`
// To add between line 68 — 69
// ... on PrismicProjectBodyVideoElement {
//   slice_type
//   primary {
//     video_url {
//       url
//     }
//   }
// }

const RenderSlices = ({ slices }) =>
  slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'image_element':
          return (
            <WrapImg key={index} className="homepage-slice-wrapper">
              <Img fluid={slice.primary.image1.localFile.childImageSharp.fluid} />
            </WrapImg>
          )
        case 'image_portrait':
          return (
            <WrapImg key={index} className="homepage-slice-portrait">
              <Img fluid={slice.primary.image1.localFile.childImageSharp.fluid} />
            </WrapImg>
          )
        case 'video_element':
          return (
            <PlayerWrapper key={index}>
              <ReactPlayer
                className="react-player"
                url={slice.primary.video_url.url}
                width="100%"
                height="100%"
              />
            </PlayerWrapper>
          )
        default:
      }
    })()
    return res
  })

const SingleProject = ({ data }) => {
  // const NextProjectMap = data.prismicProject.data.related_project.document

  const titleProjectAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const textProjectAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 600,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const imgProjectAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 600,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Layout>
      <SEO
        title={`Project - ${data.prismicProject.data.title.text}}`}
        keywords={[`Artist`, `London`, `project`, `portfolio`]}
      />
      <Hero>
        <animated.h1 style={titleProjectAnim}>{data.prismicProject.data.title.text}</animated.h1>
        {/* <animated.p style={textProjectAnim}>{data.prismicProject.data.paragraph.text}</animated.p> */}
        <TextWrap
          style={textProjectAnim}
          dangerouslySetInnerHTML={{ __html: data.prismicProject.data.paragraph.html }}
        />
        {/* <animated.p style={textProjectAnim}>{data.prismicProject.data.paragraph.text}</animated.p> */}
        <WrapSlices style={imgProjectAnim}>
          <RenderSlices slices={data.prismicProject.data.body} />
        </WrapSlices>

        {data.prismicProject.data.pdf_project.url && (
          <DownloadPdf>
            <a href={data.prismicProject.data.pdf_project.url} alt="">
              {/* <img src={pdfIcon} alt="pdf icon" /> */}
              <span>
                {/* ↓ */}
                Download project Pdf
                {/* ↓ */}
              </span>
            </a>
          </DownloadPdf>
        )}

        <NextProject>
          <Link to={`/projects/${data.prismicProject.data.related_project.uid}`}>Next Project</Link>
        </NextProject>
      </Hero>
    </Layout>
  )
}

const Hero = styled.header`
  padding-bottom: 4rem;

  h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    max-width: 400px;
    margin: 0 auto 25px auto;
    color: #000;
  }

  a {
    color: #0c2340;
    transition: background-color 0.5s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      background-color: #ededed;
    }
  }
`

const TextWrap = styled(animated.div)`
  margin: 0 auto 80px auto;
  position: relative;
  max-width: 400px;
  z-index: 100;

  @media only screen and (max-width: 769px) {
    max-width: calc(100% - 60px);
    margin: 0 auto 80px auto;
  }

  p {
    font-size: 14px;
    text-align: center;
    background-color: white;
    color: #000;
  }
`

const WrapSlices = styled(animated.div)`
  max-width: calc(100% - 50px);
  margin: 0 auto;
  position: relative;
  z-index: 12;

  @media only screen and (min-width: 769px) {
    max-width: 75%;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 55%;
  }
`

const WrapImg = styled.div`
  margin-bottom: 40px;

  &.homepage-slice-portrait {
    width: 75%;
    margin: 0 auto 40px auto;
  }
`

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 40px;

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const DownloadPdf = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  a {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: auto;
    cursor: pointer;
    color: black;
    line-height: 1.5;
    border-bottom: 1px solid transparent;
    /* padding: 10px 20px 8px 20px; */
    /* border-radius: 4px; */
    text-transform: uppercase;
    transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
      background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      /* color: #c0c0c0; */
      /* background: #ededed; */
      /* color: #ededed; */
      /* background: #2f2f2f; */
      padding: 0;
      background-color: white;
      /* color: #c0c0c0; */
      border-bottom: 1px solid black;
    }
  }

  img {
    height: 20px;
    margin-right: 0;
    margin-bottom: 10px;
  }
`

const NextProject = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-bottom: 35px;
  }

  a {
    font-size: 16px;
    border: 1px black solid;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
      background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      /* color: #c0c0c0; */
      color: #ededed;
      background: #2f2f2f;
    }
  }
`

export default SingleProject
